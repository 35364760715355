import React from 'react';

export const SteamIcon = () => {
  return (
    <svg
      width={'100%'}
      height={'100%'}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={6} cy="5.75977" r={6} fill="black" />
      <path
        d="M6 -0.240234C6.78793 -0.240234 7.56815 -0.0850398 8.2961 0.216488C9.02405 0.518017 9.68549 0.959973 10.2426 1.51712C10.7998 2.07428 11.2417 2.73571 11.5433 3.46366C11.8448 4.19162 12 4.97183 12 5.75977C12 7.35106 11.3679 8.87719 10.2426 10.0024C9.11742 11.1276 7.5913 11.7598 6 11.7598C3.24 11.7598 0.93 9.91177 0.216 7.39777L2.514 8.34576C2.59305 8.72995 2.80209 9.07516 3.10591 9.32324C3.40973 9.57133 3.78976 9.70712 4.182 9.70777C5.118 9.70777 5.88 8.94577 5.88 8.00977V7.93177L7.92 6.47377H7.968C9.216 6.47377 10.23 5.45977 10.23 4.21177C10.23 2.96377 9.216 1.94977 7.968 1.94977C6.72 1.94977 5.7 2.96377 5.7 4.21177V4.24177L4.278 6.31777L4.182 6.31177C3.828 6.31177 3.498 6.41977 3.228 6.60577L0 5.27977C0.258 2.18977 2.838 -0.240234 6 -0.240234ZM3.768 8.86177C4.248 9.05977 4.8 8.83777 4.998 8.35777C5.196 7.87777 4.968 7.33177 4.5 7.13377L3.732 6.81577C4.026 6.70777 4.356 6.70177 4.668 6.83377C4.986 6.95977 5.232 7.20577 5.358 7.52377C5.49 7.83577 5.49 8.18376 5.358 8.49576C5.1 9.14376 4.338 9.45576 3.69 9.18576C3.39 9.05976 3.162 8.83177 3.036 8.56177L3.768 8.86177ZM9.48 4.21177C9.48 5.04577 8.802 5.72377 7.968 5.72377C7.56803 5.72218 7.18499 5.56218 6.90273 5.2788C6.62047 4.99541 6.462 4.61174 6.462 4.21177C6.46121 4.01377 6.49962 3.81758 6.57502 3.63451C6.65042 3.45143 6.76132 3.2851 6.90133 3.14509C7.04133 3.00509 7.20767 2.89419 7.39074 2.81879C7.57382 2.74338 7.77001 2.70497 7.968 2.70577C8.36797 2.70576 8.75165 2.86424 9.03503 3.1465C9.31841 3.42876 9.47841 3.8118 9.48 4.21177ZM6.84 4.21177C6.84 4.83577 7.344 5.34577 7.974 5.34577C8.598 5.34577 9.102 4.83577 9.102 4.21177C9.102 3.58777 8.598 3.07777 7.974 3.07777C7.344 3.07777 6.84 3.58777 6.84 4.21177Z"
        fill="white"
      />
    </svg>
  );
};

export default SteamIcon;
