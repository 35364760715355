// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._UA8vQ2nbwjq2P5I0r1c{display:flex;justify-content:center;align-items:center;padding-bottom:10px}._UA8vQ2nbwjq2P5I0r1c .NIAwPGlL5bGBqVrVBdGq{width:100%;display:flex;flex-wrap:wrap;gap:4px;max-width:369px;align-items:center;justify-content:center}._UA8vQ2nbwjq2P5I0r1c .FGZIlSDOziTBqCcOSRT4{padding:6px;width:46px;height:68px;background:#0c0f12;display:flex;flex-direction:column;gap:4px;align-items:center;justify-content:center;box-sizing:border-box}.pGEeUhjl_dAjYUM9xTcH{display:flex;flex-direction:column;row-gap:18px;justify-content:center;align-items:center;width:100%;margin:30px 0px 30px 0px}`, "",{"version":3,"sources":["webpack://./src/Components/Molecules/Lytograms/Lytograms.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,mBAAA,CAEA,4CACE,UAAA,CACA,YAAA,CACA,cAAA,CACA,OAAA,CACA,eAAA,CACA,kBAAA,CACA,sBAAA,CAGF,4CACE,WAAA,CACA,UAAA,CACA,WAAA,CACA,kBAAA,CACA,YAAA,CACA,qBAAA,CACA,OAAA,CACA,kBAAA,CACA,sBAAA,CACA,qBAAA,CAIJ,sBACE,YAAA,CACA,qBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,UAAA,CACA,wBAAA","sourcesContent":[".wrapper {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  padding-bottom: 10px;\n\n  .lyto-box {\n    width: 100%;\n    display: flex;\n    flex-wrap: wrap;\n    gap: 4px;\n    max-width: 369px;\n    align-items: center;\n    justify-content: center;\n  }\n\n  .lyto-container {\n    padding: 6px;\n    width: 46px;\n    height: 68px;\n    background: #0c0f12;\n    display: flex;\n    flex-direction: column;\n    gap: 4px;\n    align-items: center;\n    justify-content: center;\n    box-sizing: border-box;\n  }\n}\n\n.lyto-empty-container {\n  display: flex;\n  flex-direction: column;\n  row-gap: 18px;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  margin: 30px 0px 30px 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `_UA8vQ2nbwjq2P5I0r1c`,
	"lyto-box": `NIAwPGlL5bGBqVrVBdGq`,
	"lyto-container": `FGZIlSDOziTBqCcOSRT4`,
	"lyto-empty-container": `pGEeUhjl_dAjYUM9xTcH`
};
export default ___CSS_LOADER_EXPORT___;
