import DoggyDaysCounter from '@src/Components/Molecules/Doggy/DoggyDaysCounter/DoggyDaysCounter';
import DoggyStarling from '@src/Components/Molecules/Doggy/DoggyStarling/DoggyStarling';
import React from 'react';
import styles from './Doggy.module.scss';
import dayjs from 'dayjs';
import ComicReader from '@src/Components/Organisms/ComicReader/ComicReader';
import { doggyComicImages } from './Doggy.util';

const Doggy = () => {
  const currentYear = dayjs().year();
  return (
    <>
      <main className={styles['Container']} data-testid="main-container">
        {/* First section */}
        <section className={styles['Section1']}>
          <div id="Mission1" className={styles['Mission1Container']}>
            <div
              className={styles['Background']}
              style={{
                background: `linear-gradient(0deg, #36419B 0%, #36419B 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), url(${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/doggy/Loading_screen_MISSION_1.png), lightgray -267.977px -188px / 127.485% 174.359% no-repeat`
              }}
            ></div>
            <h1>
              YOUR ACTIONS
              <br /> WORLD WIDE CONSEQUENCES
            </h1>
          </div>
          <div id="Mission2" className={styles['Mission2Container']}>
            <div
              className={styles['Mission2']}
              style={{
                background: `linear-gradient(90deg, #0A1C35 0%, rgba(10, 28, 53, 0.75) 44.38%, rgba(10, 28, 53, 0.00) 100%), url(${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/doggy/Loading_screen_MISSION_2.png) no-repeat`
              }}
            >
              <div className={styles['MissionContainer']}>
                <div className={styles['ContentContainer']}>
                  <h1>
                    YOUR ACTIONS, <br />
                    WORLD WIDE CONSEQUENCES
                  </h1>
                  <div className={styles['Badge']}>November 2023</div>
                  <div className={styles['NumberContainer']}>
                    <div className={styles['Title']}>5,000 ++</div>
                    <div className={styles['Description']}>
                      players signed-up to our pre-alpha test period and took on
                      mythics from around the world
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="Mission3" className={styles['Mission3Container']}>
            <div
              className={styles['Mission3']}
              style={{
                background: `linear-gradient(90deg, #0A1C35 0%, rgba(10, 28, 53, 0.75) 44.38%, rgba(10, 28, 53, 0.00) 100%), url(${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/doggy/Loading_screen_MISSION_3.png) no-repeat`
              }}
            >
              <div className={styles['MissionContainer']}>
                <div className={styles['ContentContainer']}>
                  <h1>
                    The Operational <br /> Standards
                  </h1>
                  <div className={styles['Subtitle']}>
                    {/* in November 2023, over */}
                  </div>
                  <div className={styles['NumberContainer']}>
                    <div className={styles['Title']}>
                      <p>53</p>
                      <span>%</span>
                    </div>
                    <div className={styles['Description']}>
                      of players chose to deploy with Starling as their
                      operative of choice.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DoggyDaysCounter />

          <DoggyStarling />
        </section>
        {/* Second Section */}
        <section id="comic" className={styles['Section2']}>
          <ComicReader images={doggyComicImages} />
        </section>
      </main>

      <footer>
        <h4>© {currentYear} Confiction Labs. All rights reserved.</h4>
        <a
          href={`${process.env.HOST_URL}/privacy-policy`}
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
      </footer>
    </>
  );
};

export default Doggy;
