export const dummyPlaytestStartCountdown = new Date('2024-10-16');
export const dummyPlaytestEndCountdown = new Date('2024-10-30');

export const dummyPlaytestStartCountdownActive =
  new Date().getTime() > dummyPlaytestStartCountdown.getTime()
    ? dummyPlaytestEndCountdown
    : dummyPlaytestStartCountdown;
export const dummyPlaytestStart = dummyPlaytestStartCountdown.toISOString();
export const dummyPlaytestEnd = dummyPlaytestEndCountdown.toISOString();

export const dummyPlaytestOrder = '3';
