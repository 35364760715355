type TSeasonData = {
  is_active: boolean;
  season_start: string;
  season_end: string;
};

export const handleTargetDate = (seasonData?: TSeasonData | null) => {
  if (seasonData) {
    if (seasonData.is_active) {
      return seasonData.season_end;
    } else if (!seasonData.is_active) {
      return seasonData.season_start;
    }
  }
};

export const handleTargetBackgroundImg = (width: number) => {
  if (width <= 1279) return `url(./assets/globe/nightsky.webp)`;
  return '';
};

export const handleTargetBackgroundGradien = (condition: boolean) => {
  if (condition) return 'transparent';
  return 'linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0) 100%)';
};
