import React from 'react';
import ReactPlayer from 'react-player';

import styles from './VideoMedia.module.scss';
import TagMedia from '../TagMedia/TagMedia';

interface IVideoMedia {
  isTag?: boolean;
  autoPlay?: boolean;
  onEnded?: () => void;
  mute?: boolean;
  volume?: number;
  url?: string;
  control?: boolean;
}

const VideoMedia: React.FC<IVideoMedia> = ({
  isTag = false,
  autoPlay = false,
  onEnded,
  mute,
  volume = 0.1,
  url = 'https://www.youtube.com/watch?v=q4gtkcOz0Jc',
  control = true
}) => {
  return (
    <section className={styles['contents']} data-testid="video-media">
      {isTag && <TagMedia />}
      <ReactPlayer
        id="video"
        url={url}
        controls={control}
        className="react-player"
        width="100%"
        height="100%"
        onEnded={onEnded}
        playing={true}
        volume={volume}
        muted={mute}
        config={{
          youtube: {
            playerVars: {
              showinfo: 0,
              autoplay: autoPlay ? 1 : 0,
              controls: 0,
              modestbranding: 1,
              rel: 0,
              frameborder: 0,
              mute: mute ? 1 : 0
            }
          }
        }}
      />
    </section>
  );
};

export default VideoMedia;
