// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UDk1RPb_vm4rcbFLtwEg,button:disabled{all:unset;border-radius:4px;border:1px solid var(--secondary-magenta-300);background:var(--secondary-magenta-400);box-shadow:0px 4px 4px 0px rgba(0,0,0,.25);color:var(--neutral-white-500);text-align:center;font-family:var(--font-normal);font-size:12px;font-style:normal;font-weight:600;line-height:14px;transition:.1s all ease-in-out;display:flex;align-items:center}.UDk1RPb_vm4rcbFLtwEg img,button:disabled img{width:15px;height:auto}.UDk1RPb_vm4rcbFLtwEg span,button:disabled span{margin-left:8px}button:disabled{opacity:.65}.UDk1RPb_vm4rcbFLtwEg:hover:not(:disabled){background:var(--secondary-magenta-300)}`, "",{"version":3,"sources":["webpack://./src/Components/Atoms/ButtonDefault/ButtonDefault.module.scss"],"names":[],"mappings":"AAAA,sCAEE,SAAA,CACA,iBAAA,CACA,6CAAA,CACA,uCAAA,CACA,0CAAA,CAEA,8BAAA,CACA,iBAAA,CAEA,8BAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CAEA,8BAAA,CAEA,YAAA,CACA,kBAAA,CAEA,8CACE,UAAA,CACA,WAAA,CAGF,gDACE,eAAA,CAIJ,gBACE,WAAA,CAGF,2CACE,uCAAA","sourcesContent":[".container__button,\nbutton:disabled {\n  all: unset;\n  border-radius: 4px;\n  border: 1px solid var(--secondary-magenta-300);\n  background: var(--secondary-magenta-400);\n  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);\n\n  color: var(--neutral-white-500);\n  text-align: center;\n\n  font-family: var(--font-normal);\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 600;\n  line-height: 14px;\n\n  transition: 0.1s all ease-in-out;\n\n  display: flex;\n  align-items: center;\n\n  img {\n    width: 15px;\n    height: auto;\n  }\n\n  span {\n    margin-left: 8px;\n  }\n}\n\nbutton:disabled {\n  opacity: 0.65;\n}\n\n.container__button:hover:not(:disabled) {\n  background: var(--secondary-magenta-300);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container__button": `UDk1RPb_vm4rcbFLtwEg`
};
export default ___CSS_LOADER_EXPORT___;
