import React from 'react';

import styles from './DoggyComicCarousel.module.scss';

interface DoggyComicCarouselProps {
  images: string[];
  current: number;
}

const DoggyComicCarousel: React.FC<DoggyComicCarouselProps> = ({
  images,
  current
}) => {
  return (
    <div className={styles['Content']}>
      {images.map((image, key) => {
        return (
          <>
            {current === key ? (
              <img
                key={`image ${Number(key)}`}
                src={image}
                alt={'image ' + key}
              />
            ) : null}
          </>
        );
      })}
    </div>
  );
};

export default DoggyComicCarousel;
