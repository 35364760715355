// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EBmvuScOBjt7bhxUvYhA{width:32px;height:32px;box-sizing:border-box;border-radius:50%;border:2px solid #18b5bd;display:flex;justify-content:center;align-items:center}.EBmvuScOBjt7bhxUvYhA.am8GsXc6lOxVh1QQKujc{background:#18b5bd}@media screen and (max-width: 767px){.EBmvuScOBjt7bhxUvYhA{width:24px;height:24px}.EBmvuScOBjt7bhxUvYhA img{width:13.33px;height:10.18px}}`, "",{"version":3,"sources":["webpack://./src/Components/Atoms/StepItem/StepItem.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CACA,qBAAA,CAEA,iBAAA,CAEA,wBAAA,CAEA,YAAA,CACA,sBAAA,CACA,kBAAA,CAEA,2CACE,kBAAA,CAIJ,qCACE,sBACE,UAAA,CACA,WAAA,CAEA,0BACE,aAAA,CACA,cAAA,CAAA","sourcesContent":[".container {\n  width: 32px;\n  height: 32px;\n  box-sizing: border-box;\n\n  border-radius: 50%;\n\n  border: 2px solid #18b5bd;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  &.blue {\n    background: #18b5bd;\n  }\n}\n\n@media screen and (max-width: 767px) {\n  .container {\n    width: 24px;\n    height: 24px;\n\n    img {\n      width: 13.33px;\n      height: 10.18px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `EBmvuScOBjt7bhxUvYhA`,
	"blue": `am8GsXc6lOxVh1QQKujc`
};
export default ___CSS_LOADER_EXPORT___;
