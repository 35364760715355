// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TV3UTjflaVPRLyI5eWrv{width:100%;height:100%;box-sizing:border-box;display:flex;justify-content:center;align-items:center;background:var(--primary-blue-400-v23);border-radius:4px;border:.5px solid var(--primary-blue-300-v23);cursor:pointer;transition:.2s all ease-in-out}.TV3UTjflaVPRLyI5eWrv.Hgm9yTVtByHwArXNC5uc:hover{background:var(--primary-blue-400-v23)}.TV3UTjflaVPRLyI5eWrv:hover{border:1px solid var(--secondary-magenta-300);background:var(--secondary-magenta-400)}.TV3UTjflaVPRLyI5eWrv img{width:18px;height:18px;box-sizing:border-box}`, "",{"version":3,"sources":["webpack://./src/Components/Atoms/Button/ButtonNavigation/ButtonNavigation.module.scss"],"names":[],"mappings":"AAAA,sBACI,UAAA,CACA,WAAA,CACA,qBAAA,CAEA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,sCAAA,CACA,iBAAA,CACA,6CAAA,CACA,cAAA,CACA,8BAAA,CAGI,iDACI,sCAAA,CAKR,4BACI,6CAAA,CACA,uCAAA,CAGJ,0BACI,UAAA,CACA,WAAA,CACA,qBAAA","sourcesContent":[".container {\n    width: 100%;\n    height: 100%;\n    box-sizing: border-box;\n\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background: var(--primary-blue-400-v23);\n    border-radius: 4px;\n    border: 0.5px solid var(--primary-blue-300-v23);\n    cursor: pointer;\n    transition: 0.2s all ease-in-out;\n\n    &.locked {\n        &:hover {\n            background: var(--primary-blue-400-v23);\n        }\n    }\n\n\n    &:hover {\n        border: 1px solid var(--secondary-magenta-300);\n        background: var(--secondary-magenta-400);\n    }\n\n    img {\n        width: 18px;\n        height: 18px;\n        box-sizing: border-box;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `TV3UTjflaVPRLyI5eWrv`,
	"locked": `Hgm9yTVtByHwArXNC5uc`
};
export default ___CSS_LOADER_EXPORT___;
