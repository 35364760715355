// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BWCE08qUYhI1J0P0bg5r{width:100%;height:140px;border-radius:0px 0px 4px 4px;box-sizing:border-box;background-size:cover;background-position:center;background-repeat:no-repeat;display:flex;justify-content:center;align-items:flex-end}.BWCE08qUYhI1J0P0bg5r .dQbKw02gUioFRupp8Wnp{width:100%;height:max-content;display:flex;justify-content:center;align-items:center;margin-bottom:18px}.BWCE08qUYhI1J0P0bg5r .dQbKw02gUioFRupp8Wnp .wxhfuByektN_0v8dKSPj{width:180.5px;display:flex;justify-content:center;height:36px}@media screen and (max-width: 767px){.BWCE08qUYhI1J0P0bg5r .dQbKw02gUioFRupp8Wnp .wxhfuByektN_0v8dKSPj{width:162.42px;height:32.39px}}`, "",{"version":3,"sources":["webpack://./src/Components/Molecules/GameLiveTiles/GameLiveTiles.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,YAAA,CACA,6BAAA,CAEA,qBAAA,CACA,qBAAA,CACA,0BAAA,CACA,2BAAA,CAEA,YAAA,CACA,sBAAA,CACA,oBAAA,CAEA,4CACE,UAAA,CACA,kBAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,kBAAA,CAEA,kEACE,aAAA,CAEA,YAAA,CACA,sBAAA,CACA,WAAA,CAKN,qCAGM,kEACE,cAAA,CACA,cAAA,CAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `BWCE08qUYhI1J0P0bg5r`,
	"content-wrapper": `dQbKw02gUioFRupp8Wnp`,
	"button-wrapper": `wxhfuByektN_0v8dKSPj`
};
export default ___CSS_LOADER_EXPORT___;
