import React from 'react';
import styles from './ButtonLiveTiles.module.scss';
import SteamIcon from '../../SteamIcon/SteamIcon';
import { OpenInNewIcon } from '../../Icons/OpenInNewIcon/OpenInNewIcon';
import {
  dummyPlaytestEndCountdown,
  dummyPlaytestStartCountdown
} from '@src/Pages/FullScreenGlobe/FullScreenGlobe.utils';

interface IButtonLiveTiles {
  color: 'pink' | 'black';
  onClick?: () => void;
}

const ButtonLiveTiles: React.FC<IButtonLiveTiles> = ({ color, onClick }) => {
  const handleIcon = () => {
    if (color === 'pink') {
      return <OpenInNewIcon />;
    } else if (color === 'black') {
      return <SteamIcon />;
    }
  };

  return (
    <button
      className={`${styles['button-container']} ${
        styles['button-container-' + color]
      }`}
      onClick={onClick}
    >
      <div className={styles['button-content']}>
        {color === 'pink' ? (
          <>
            <p className={styles['button-text']}>VIEW MISSIONS</p>
            <div className={styles['icon-wrapper']}>{handleIcon()}</div>
          </>
        ) : (
          <>
            <div className={styles['icon-wrapper']}>{handleIcon()}</div>
            <p className={styles['button-text']}>
              {new Date().getTime() > dummyPlaytestStartCountdown.getTime() &&
              new Date().getTime() < dummyPlaytestEndCountdown.getTime()
                ? 'PLAY NOW'
                : 'WISHLIST'}
            </p>
          </>
        )}
      </div>
    </button>
  );
};

export default ButtonLiveTiles;
