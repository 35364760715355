// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cqDpOdcAtQvbaPWXjLrg{display:flex;flex-direction:column;gap:24px;max-width:1285px}@media screen and (min-width: 1280px)and (max-width: 1919px){.cqDpOdcAtQvbaPWXjLrg{max-width:920px}}@media screen and (min-width: 768px)and (max-width: 1279px){.cqDpOdcAtQvbaPWXjLrg{max-width:100%}}`, "",{"version":3,"sources":["webpack://./src/Components/Organisms/ShadeResurgence/ShadeResurgenceComic/ShadeResurgenceComic.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,QAAA,CAEA,gBAAA,CAGF,6DACE,sBACE,eAAA,CAAA,CAIJ,4DACE,sBACE,cAAA,CAAA","sourcesContent":[".Container {\n  display: flex;\n  flex-direction: column;\n  gap: 24px;\n\n  max-width: 1285px;\n}\n\n@media screen and (min-width: 1280px) and (max-width: 1919px) {\n  .Container {\n    max-width: 920px;\n  }\n}\n\n@media screen and (min-width: 768px) and (max-width: 1279px) {\n  .Container {\n    max-width: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Container": `cqDpOdcAtQvbaPWXjLrg`
};
export default ___CSS_LOADER_EXPORT___;
