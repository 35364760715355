import DoggyComicPagination from '@src/Components/Molecules/Doggy/DoggyComicPagination/DoggyComicPagination';
import React, { useState } from 'react';
import styles from './ShadeResurgenceComic.module.scss';
import DoggyComicCarousel from '@src/Components/Molecules/Doggy/DoggyComicCarousel/DoggyComicCarousel';
const images = [
  `${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/shade_resurgence/comic/cover.png`,
  `${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/shade_resurgence/comic/comic1.png`,
  `${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/shade_resurgence/comic/comic2.png`,
  `${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/shade_resurgence/comic/comic3.png`,
  `${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/shade_resurgence/comic/comic4.png`,
  `${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/shade_resurgence/comic/comic5.png`
];

const ShadeResurgenceComic = () => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  return (
    <div className={styles['Container']}>
      <DoggyComicPagination
        totalPage={images.length}
        activeIndex={currentIndex}
        onChangePage={(page: number) => {
          //
          setCurrentIndex(page);
        }}
      />
      <DoggyComicCarousel images={images} current={currentIndex} />
      <DoggyComicPagination
        totalPage={images.length}
        activeIndex={currentIndex}
        onChangePage={(page: number) => {
          //
          setCurrentIndex(page);
        }}
      />
    </div>
  );
};

export default ShadeResurgenceComic;
