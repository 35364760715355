import React from 'react';
import styles from './TheWard.module.scss';
import ButtonDefault from '@src/Components/Atoms/ButtonDefault/ButtonDefault';
import useWindowDimensions from '@src/Common/Hooks/useWindowDimensions';

import dayjs from 'dayjs';
import ComicReader from '@src/Components/Organisms/ComicReader/ComicReader';
import { theWardComicImages } from './TheWard.util';

const TheWard = () => {
  const { width } = useWindowDimensions();

  const handleBackground2Assets = () => {
    if (width >= 1920) {
      return 'background2.png';
    } else if (width >= 1280 && width < 1920) {
      return 'background2-1280.png';
    } else if (width >= 768 && width < 1280) {
      return 'background2-768.png';
    } else if (width < 768) {
      return 'background2-375.png';
    }
  };

  const handleGhostImage = () => {
    if (width >= 1920) {
      return 'ghost.png';
    } else if (width >= 1280 && width < 1920) {
      return 'ghost-1280.png';
    } else if (width >= 768 && width < 1280) {
      return 'ghost-768.png';
    } else if (width < 768) {
      return 'ghost-375.png';
    }
  };

  const currentYear = dayjs().year();
  return (
    <>
      <main className={styles['Container']} data-testid="main-container">
        <div className={styles['section1b']}>
          <div className={styles['paragraph-wrapper']}>
            <p className={styles['paragraph']}>
              {width >= 1920 && (
                <>
                  C-6652 is the designation given to a phantasmal entity <br />{' '}
                  whose touch is fatal to humans. A shade-touched human, when
                  dead, will give rise to another C-6652. The incarnation of the
                  original shade is only half-understood.
                </>
              )}
              {width < 1920 && (
                <>
                  C-6652 is the designation given to a phantasmal entity whose
                  touch is fatal to humans. A shade-touched human, when dead,
                  will give rise to another C-6652. The incarnation of the
                  original shade is only half-understood.
                </>
              )}
            </p>
          </div>
        </div>
        <div
          className={styles['section1c']}
          style={{
            backgroundImage: `url(${
              process.env.REACT_APP_GCM_BUCKET_URL
            }/mythic-portal-v2/img/png/shade_resurgence/${handleBackground2Assets()})`
          }}
        >
          <div className={styles['shadow']}></div>
          <div className={styles['shadow2']}></div>
          <div className={styles['section1c-content-wrapper']}>
            <img
              src={`${
                process.env.REACT_APP_GCM_BUCKET_URL
              }/mythic-portal-v2/img/png/shade_resurgence/${handleGhostImage()}`}
              alt="ghost-image"
            />
            <p className={styles['badge']}>spectral</p>
            <div className={styles['content-wrapper']}>
              <div className={styles['left-content']}>
                <div className={styles['first-row']}>
                  <div className={styles['icon-wrapper']}>
                    <img
                      src={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/shade_resurgence/book-icon.png`}
                      alt="book-icon"
                    />
                  </div>
                  <div className={styles['text']}>
                    You can read Casefile: C-6652 <br /> and explore our growing{' '}
                    <br /> universe at the Repository.
                  </div>
                </div>
                <div className={styles['second-row']}>
                  <div className={styles['button-wrapper']}>
                    <ButtonDefault
                      text="Read The Entry"
                      icon="./assets/img/svg/learn-more-icon.svg"
                      onClick={() => {
                        window.open(
                          process.env.URL_MYTHIC_REPOSITORY + '/C-6652'
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={styles['right-content']}>
                <p> C-6652</p>
                <p>the ward</p>
              </div>
            </div>
          </div>
        </div>
        {/* Second Section */}
        <section id="comic" className={styles['Section2']}>
          <ComicReader images={theWardComicImages} />
        </section>
      </main>

      <footer>
        <h4>© {currentYear} Confiction Labs. All rights reserved.</h4>
        <a
          href={`${process.env.HOST_URL}/privacy-policy`}
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
      </footer>
    </>
  );
};

export default TheWard;
