import React from 'react';
import styles from './ButtonNavigation.module.scss';

type IButtonNavigation = {
  type?:
    | 'notification'
    | 'mail'
    | 'group'
    | 'settings'
    | 'addPerson'
    | 'achievement'
    | 'note';
  onClick?: () => void;
  isLocked?: boolean;
};

const ButtonNavigation: React.FC<IButtonNavigation> = ({
  type = 'note',
  onClick,
  isLocked = false
}) => {
  const getIcon = (name: string) => {
    switch (name) {
      case 'notification':
        return 'notification-new.svg';
      case 'mail':
        return 'mail-new.svg';
      case 'group':
        return 'group.svg';
      case 'settings':
        return 'setting.svg';
      case 'addPerson':
        return 'person_add.svg';
      case 'achievement':
        return 'achievement-new.svg';
      case 'note':
        return 'note-lists.svg';
      default:
        return 'notification-18px.svg';
    }
  };
  return (
    <div
      className={`${styles['container']} ${isLocked && styles['locked']}`}
      onClick={() => (!isLocked ? onClick?.() : null)}
      data-testid="button-navigation"
      onKeyDown={() => {
        return;
      }}
    >
      {isLocked ? (
        <img src="./assets/img/svg/lock-icon.svg" alt="lock-icon" />
      ) : (
        // <img
        //   src={`${
        //     process.env.REACT_APP_GCM_BUCKET_URL
        //   }/mythic-portal-v2/img/svg/${getIcon(type ?? '')}`}
        //   alt="icon"
        // />
        <img
          src={`assets/img/svg/button-navigation/${getIcon(type ?? '')}`}
          alt="icon"
        />
      )}
    </div>
  );
};

export default ButtonNavigation;
