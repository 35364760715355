// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.E6vHcLuPjnQGnVnGSpCJ{width:100%;box-sizing:border-box;display:flex;flex-direction:column;align-items:flex-start;justify-content:flex-start;position:relative}.E6vHcLuPjnQGnVnGSpCJ .s99wUyNVIRwfgW0IKOlN{width:100%;height:max-content;box-sizing:border-box}.E6vHcLuPjnQGnVnGSpCJ .UU9g0fz1ba2aLcDOkJbF{width:100%;box-sizing:border-box}.E6vHcLuPjnQGnVnGSpCJ ._fB1Y9ool_9do7siMuCR{width:26px;height:108px;display:flex;justify-content:center;align-items:center;background:rgba(12,35,66,.9);border-radius:4px 0px 0px 4px;font-family:"Barlow",sans-serif;font-size:14px;font-style:normal;font-weight:600;line-height:24px;color:var(--secondary-tosca-300);position:absolute;top:0;left:-29px}`, "",{"version":3,"sources":["webpack://./src/Components/Molecules/GameBannerSection/GameBannerSection.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,qBAAA,CAEA,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,0BAAA,CACA,iBAAA,CAEA,4CACE,UAAA,CACA,kBAAA,CACA,qBAAA,CAGF,4CACE,UAAA,CACA,qBAAA,CAGF,4CACE,UAAA,CACA,YAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,4BAAA,CACA,6BAAA,CAEA,+BAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CACA,gCAAA,CACA,iBAAA,CACA,KAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `E6vHcLuPjnQGnVnGSpCJ`,
	"top-content": `s99wUyNVIRwfgW0IKOlN`,
	"bottom-content": `UU9g0fz1ba2aLcDOkJbF`,
	"lable": `_fB1Y9ool_9do7siMuCR`
};
export default ___CSS_LOADER_EXPORT___;
