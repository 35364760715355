import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styles from './Countdown.module.scss';
import { seasonStatusText } from './Countdown.utils';

type ICountdown = {
  targetDate: Date;
  isActive: boolean;
  seasonStart?: string;
  seasonEnd?: string;
  playTestOrder?: string;
};

const dummyDate = new Date();

const Countdown: React.FC<ICountdown> = ({
  targetDate = dummyDate,
  isActive,
  seasonStart,
  seasonEnd,
  playTestOrder = '3'
}) => {
  const calculateTimeLeft = () => {
    const difference = targetDate.getTime() - new Date().getTime();
    if (difference <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds, difference };
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [targetDate]);

  const formattedSeasonStart = moment(seasonStart).format('MMMM D'); // 'October 16'
  const formattedSeasonEnd = moment(seasonEnd).format('D YYYY'); // '30, 2024'

  // Combine the formatted start and end
  const seasonRange = `${formattedSeasonStart} - ${formattedSeasonEnd}`;

  return (
    <div className={styles['container']}>
      <div className={styles['left-container']}>
        <p className={styles['title']}>PLAYTEST {playTestOrder}</p>
        <p className={styles['title-date']}>{seasonRange}</p>
      </div>
      <div
        className={styles['divider']}
        style={{
          width: '2px',
          height: '100%',
          backgroundColor: '#0D2445'
        }}
      ></div>
      <div className={styles['right-container']}>
        <div className={styles['right-content']}>
          <p className={styles['text-detail']}>
            {seasonStatusText(isActive, timeLeft.difference ?? 0)}
          </p>
          <div className={styles['time-container']}>
            <div
              className={`${styles['time-content']} ${styles['time-content-days']}`}
            >
              <p className={styles['number']}>{timeLeft.days}D</p>
            </div>
            <div className={styles['separator']}>:</div>
            <div
              className={`${styles['time-content']} ${styles['time-content-hours']}`}
            >
              <p className={styles['number']}>{timeLeft.hours}H</p>
            </div>
            <div className={styles['separator']}>:</div>
            <div
              className={`${styles['time-content']} ${styles['time-content-minutes']}`}
            >
              <p className={styles['number']}>{timeLeft.minutes}M</p>
            </div>
            <div className={styles['separator']}>:</div>
            <div
              className={`${styles['time-content']} ${styles['time-content-seconds']}`}
            >
              <p className={styles['number']}>{timeLeft.seconds}S</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Countdown;
