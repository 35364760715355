// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.knm9LpwJY9DGNLZUwOSJ{width:100%;height:100%;position:relative;box-shadow:0px 4px 4px 0px rgba(0,0,0,.25);border-radius:0px 4px 4px 4px}@media screen and (max-width: 767px){.knm9LpwJY9DGNLZUwOSJ{height:193px}}.knm9LpwJY9DGNLZUwOSJ iframe{border-radius:4px;-webkit-border-radius:4px;-moz-border-radius:4px;-ms-border-radius:4px;-o-border-radius:4px}`, "",{"version":3,"sources":["webpack://./src/Components/Molecules/Media/VideoMedia/VideoMedia.module.scss"],"names":[],"mappings":"AAAA,sBACE,UAAA,CACA,WAAA,CACA,iBAAA,CAEA,0CAAA,CACA,6BAAA,CAEA,qCARF,sBASI,YAAA,CAAA,CAGF,6BACE,iBAAA,CACA,yBAAA,CACA,sBAAA,CACA,qBAAA,CACA,oBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contents": `knm9LpwJY9DGNLZUwOSJ`
};
export default ___CSS_LOADER_EXPORT___;
