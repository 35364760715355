import React from 'react';
import GameLiveTiles from '../GameLiveTiles/GameLiveTiles';
import styles from './GameBannerSection.module.scss';
import Countdown from '../Countdown/Countdown';

type IGameBannerSection = {
  targetDate: Date;
  isActive?: boolean;
  seasonStart?: string;
  seasonEnd?: string;
  playTestOrder?: string;
};

const currentDate = new Date();
currentDate.setDate(currentDate.getDate() + 7);

const GameBannerSection: React.FC<IGameBannerSection> = ({
  targetDate = currentDate,
  isActive = true,
  playTestOrder = '3rd',
  seasonStart = new Date().toISOString(),
  seasonEnd = new Date().toISOString()
}) => {
  const isActiveCountdown = true;

  const viewCountDown = () => {
    if (!!seasonStart && !!seasonEnd) {
      return (
        <div className={styles['top-content']}>
          <Countdown
            playTestOrder={playTestOrder}
            isActive={isActive}
            targetDate={targetDate}
            seasonStart={seasonStart}
            seasonEnd={seasonEnd}
          />
        </div>
      );
    }
  };

  return (
    <div className={styles['container']} data-testid="game-banner-section">
      {isActiveCountdown && viewCountDown()}
      <div className={styles['bottom-content']}>
        <GameLiveTiles />
      </div>
    </div>
  );
};

export default GameBannerSection;
