import { injectStores } from '@mobx-devtools/tools';
import {
  IdempotencyKeyStore,
  IdempotencyKeyStoreDefault
} from '@src/Common/Services/Idempotency/IdempotencyKey.store';
import { GlobeStore, GlobeStoreDefault } from '@src/Domain/Globe/Globe.store';
import {
  MessageStore,
  MessageStoreDefault
} from '@src/Domain/Message/Message.store';
import {
  ProfileStore,
  ProfileStoreDefault
} from '@src/Domain/Profile/Profile.store';
import {
  IUserStore,
  UserStore,
  UserStoreDefault
} from '@src/Domain/User/User.store';
import {
  IThreatIndexStore,
  ThreatIndexStore,
  ThreatIndexStoreDefault
} from '@src/Domain/ThreatIndex/ThreatIndex.store';
import { configure } from 'mobx';
configure({ useProxies: 'never', enforceActions: 'never' });

export class RootStore {
  UserStore: IUserStore;
  IdempotencyKeyStore: IdempotencyKeyStore;
  GlobeStore: GlobeStore;
  ProfileStore: ProfileStore;
  MessageStore: MessageStore;
  ThreatIndexStore: IThreatIndexStore;

  constructor() {
    this.UserStore = new UserStore();
    this.IdempotencyKeyStore = new IdempotencyKeyStore();
    this.GlobeStore = new GlobeStore();
    this.ProfileStore = new ProfileStore();
    this.MessageStore = new MessageStore();
    this.ThreatIndexStore = new ThreatIndexStore();
  }
}

export const defaultStore: RootStore = {
  UserStore: UserStoreDefault,
  IdempotencyKeyStore: IdempotencyKeyStoreDefault,
  GlobeStore: GlobeStoreDefault,
  ProfileStore: ProfileStoreDefault,
  MessageStore: MessageStoreDefault,
  ThreatIndexStore: ThreatIndexStoreDefault
};

injectStores({ rootStore: new RootStore() });
