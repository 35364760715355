import React from 'react';
import { useLocation } from 'react-router-dom';
import styles from './Footer.module.scss';
import dayjs from 'dayjs';

const Footer = () => {
  const { pathname } = useLocation();
  const currentYear = dayjs().year();
  function hideFooter() {
    if (pathname === '/termsofuse') {
      return true;
    } else if (pathname === '/communityagreement') {
      return true;
    } else {
      return false;
    }
  }

  const retainerAcademyStyle = {
    marginTop: '-55px',
    paddingBottom: '23px'
  };

  return (
    <div
      data-testid="footer"
      className={pathname === '/profile' ? styles['footer-space'] : ''}
    >
      {!hideFooter() && (
        <div
          className={styles['footer-wrapper']}
          style={pathname !== '/retaineracademy' ? {} : retainerAcademyStyle}
        >
          <div className={styles['footer-text']}>
            &copy; {currentYear} Confiction Labs. All rights reserved.
          </div>
          <div className={styles['additional-info']}>
            <a
              target="_blank"
              rel="noreferrer"
              href={`${process.env.HOST_URL}/privacy-policy`}
            >
              Privacy Policy
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default Footer;
