// NOTE:
// - if globe size not changed, adjust zoomIn & zoomOut value

export const handleGlobeBehaviorScreen = (
  width: number,
  isFloatingEventVisible: boolean
) => {
  if (width >= 3840) {
    return handleGlobeNormal4KScreen();
  } else if (width >= 2560 && width <= 3839) {
    return handleGlobeNormal2KScreen();
  } else if (width >= 1921 && width <= 2559) {
    return handleGlobeSmall2KScreen();
  } else if (width >= 1660 && width <= 1920) {
    return handleGlobeNormalDesktopScreen(isFloatingEventVisible);
  } else if (width >= 1280 && width <= 1659) {
    return handleGlobeSmallDesktopScreen(isFloatingEventVisible);
  } else if (width >= 768 && width <= 1279) {
    return handleGlobeTabletScreen();
  } else if (width >= 475 && width <= 767) {
    return handleGlobeMobileScreen();
  } else if (width <= 474) {
    return handleGlobeSmallMobileScreen();
  }
};

//above 3840px
const handleGlobeNormal4KScreen = () => {
  return {
    canvasWidth: window.innerWidth + 900,
    canvasHeight: window.innerHeight,
    marginTop: 0,
    globeSize: 7.1,
    marginLeft: '-10%',
    zoomOut: 300,
    zoomIn: 650
  };
};

// 2560px to 3839px
const handleGlobeNormal2KScreen = () => {
  return {
    canvasWidth: window.innerWidth + 900,
    canvasHeight: window.innerHeight,
    marginTop: 0,
    globeSize: 6.1,
    marginLeft: 0,
    zoomOut: 300,
    zoomIn: 450
  };
};

// 1921px to 2559px
const handleGlobeSmall2KScreen = () => {
  return {
    canvasWidth: window.innerWidth + 900,
    canvasHeight: window.innerHeight,
    marginTop: 0,
    globeSize: 1.6,
    marginLeft: 0,
    zoomOut: 400,
    zoomIn: 650
  };
};

// 1660px to 1920px
const handleGlobeNormalDesktopScreen = (isFloatingEventVisible: boolean) => {
  return {
    canvasWidth: window.innerWidth + 900,
    canvasHeight: window.innerHeight,
    marginTop: 0,
    globeSize: 2.2,
    marginLeft: isFloatingEventVisible ? 20 : 100,
    zoomOut: 280,
    zoomIn: 300
  };
};

// 1280px to 1659px
const handleGlobeSmallDesktopScreen = (isFloatingEventVisible: boolean) => {
  return {
    canvasWidth: window.innerWidth + 900,
    canvasHeight: window.innerHeight,
    marginTop: 0,
    globeSize: 3.0,
    marginLeft: isFloatingEventVisible ? 20 : 640,
    zoomOut: 280,
    zoomIn: 300
  };
};

// 768px to 1279px
const handleGlobeTabletScreen = () => {
  return {
    canvasWidth: window.innerWidth,
    canvasHeight: window.innerHeight - 200,
    marginTop: 0,
    globeSize: 3.0,
    marginLeft: 0,
    zoomOut: 400,
    zoomIn: 400
  };
};

// 475px to 767px
const handleGlobeMobileScreen = () => {
  return {
    canvasWidth: window.innerWidth,
    canvasHeight: window.innerHeight - 200,
    marginTop: 0,
    globeSize: 1.6,
    marginLeft: 0,
    zoomOut: 400,
    zoomIn: 400
  };
};

// 474px
const handleGlobeSmallMobileScreen = () => {
  return {
    canvasWidth: window.innerWidth,
    canvasHeight: window.innerHeight - 250,
    marginTop: 0,
    globeSize: 1.6,
    marginLeft: 0,
    zoomOut: 400,
    zoomIn: 410
    // zoomOut: 500,
    // zoomIn: 510
  };
};

export const markerSvgCountry = (
  content: any,
  isHoveringCountry: boolean,
  hoveringCountryName: string,
  data: any,
  isFirstOpen: boolean
) => {
  if (content.type === 'riftstorm') {
    return `<div>
     <div onclick="window.open('${
       process.env.WEB_RIFTSTORM_URL
     }', '_blank')" style="background: url(./assets/globe/riftstorm_event.png) center" class="${
      data?.selectedCountry?.name === content.name
        ? 'img-riftstorm-icon-active'
        : 'img-riftstorm-icon'
    }"></div>
    </div>`;
  } else {
    return `<div>
      <div class="${
        data?.selectedCountry?.name === content.name && !isFirstOpen
          ? 'pulsating-circle-active'
          : `pulsating-circle`
      }"></div>
      <div style="visibility: ${
        isHoveringCountry && hoveringCountryName === content.name
          ? 'visible;'
          : 'hidden;'
      }" class="tooltip-country">
        <div class="title">${content.name}</div>
        <div class="subtitle">${content.totalEvent} ${
      content.totalEvent > 1 ? 'New Events' : 'New Event'
    }</div>
      </div>
    </div>`;
    // return ` <div style="width: 20; height: 20; background: 'salmon'">
    //  </div>`;
  }
};

export const enableScroll = () => {
  document.body.style.overflow = '';
};
