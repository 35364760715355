// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.joFc5SFDm7aZJch9trxT{background:linear-gradient(90deg, rgba(240, 167, 55, 0) 0%, #f0a737 49.33%, rgba(240, 167, 55, 0) 100%);width:100%;min-height:8px;position:absolute}`, "",{"version":3,"sources":["webpack://./src/Components/Atoms/goldLine/goldLine.module.scss"],"names":[],"mappings":"AAAA,sBACE,uGAAA,CAMA,UAAA,CACA,cAAA,CACA,iBAAA","sourcesContent":[".goldLine {\n  background: linear-gradient(\n    90deg,\n    rgba(240, 167, 55, 0) 0%,\n    #f0a737 49.33%,\n    rgba(240, 167, 55, 0) 100%\n  );\n  width: 100%;\n  min-height: 8px;\n  position: absolute;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"goldLine": `joFc5SFDm7aZJch9trxT`
};
export default ___CSS_LOADER_EXPORT___;
