import { StoreContext, withStore } from '@src/Store/Store.context';
import axios from 'axios';
import { observer } from 'mobx-react-lite';
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback
} from 'react';
import { useAuth } from 'react-oidc-context';

import useWindowDimensions from '@src/Common/Hooks/useWindowDimensions';
import { useHome } from '@src/Common/Hooks/useHome';
import { useEventCountry } from '@src/Common/Hooks/useEventCountry';
import { handleTargetBackgroundImg } from '@src/Common/Constants/handleTarget';
import { useHomeScroll } from '@src/Common/Hooks/useHomeScroll';

import AchievementModal from '@src/Components/Atoms/Modal/AchievementModal/AchievementModal';
import Footer from '@src/Components/Layouts/Footer/Footer';
import NotificationMessage from '@src/Components/Molecules/Notification/NotficationMessage';
import NotificationRedesign from '@src/Components/Molecules/Notification/NotificationRedesign';

import styles from './FullScreenGlobe.module.scss';
import { CountryType } from './Globe/Globe.type';
import GlobeMythic from './Globe';
import FooterDesktop from '@src/Components/Molecules/FooterDesktop/FooterDesktop';
import CardConicGradient from '@src/Components/Molecules/CardConicGradient/CardConicGradient';
import CardSectionWrapper from '@src/Components/Molecules/CardSectionWrapper/CardSectionWrapper';
import FabGroupButton from '@src/Components/Molecules/FabGroupButton/FabGroupButton';
import ModalAssignment from '@src/Components/Molecules/ModalAssignment/ModalAssignment';
import ModalGrantedInduction from '@src/Components/Molecules/ModalGrantedInduction/ModalGrantedInduction';
import PortalOpeningVideo from '@src/Components/Molecules/PortalOpeningVideo/PortalOpeningVideo';
import { Tooltip } from 'react-tooltip';

const FullScreenGlobe = observer(() => {
  const { GlobeStore, ThreatIndexStore } = useContext(StoreContext);
  const { width } = useWindowDimensions();
  const { isAuthenticated } = useAuth();

  const tileRef = useRef<HTMLDivElement>(null);
  const homepageRef = useRef<HTMLDivElement>(null);
  const profileRef = useRef<HTMLDivElement>(null);

  const [indexCountry, setIndexCountry] = useState<number>(0);
  const [country, setCountry] = useState<CountryType[]>([]);
  const [faction, setFaction] = useState<any>(undefined);
  const [showModalAchievement, setShowModalAchievement] =
    useState<boolean>(false);
  const [_seasonData, setSeasonData] = useState<{
    is_active: boolean;
    season_start: string;
    season_end: string;
  } | null>(null);
  const [userPointData, setUserPointData] = useState<{
    pointId: number;
    pointName: string;
    totalPoints: number;
    level: number;
    currentLevelPoint: number;
    requiredPointToNextLevel: number;
  }>({
    pointId: 0,
    pointName: '',
    totalPoints: 0,
    level: 0,
    currentLevelPoint: 0,
    requiredPointToNextLevel: 0
  });
  const [accountLevel, setAccountLevel] = useState<number>(0);
  const [showModalAssignment, setShowModalAssignment] =
    useState<boolean>(false);
  const [showVideoLoading, setShowVideoLoading] = useState<boolean>(false);

  const tooltipRef: React.RefObject<HTMLDivElement> = useRef(null);

  const {
    scrollToHomepageTop,
    //  isTileContentAtBot,
    isTileContentAtTop
  } = useHomeScroll({
    tileRef,
    homepageRef,
    profileRef
  });

  const {
    setShowNotification,
    showNotification,
    dataNotices,
    unreadLengthNotice,
    readLengthNotice,
    updateStatusNotice,
    getStatusNotice,
    markAsReadNotice,
    updateArrayRemovedIdNotice,
    ProfileStore,
    userToken,
    signInHandle,
    showModalMessages,
    setShowModalMessages,
    mailboxData,
    unreadMailbox,
    readMailbox,
    markAsReadMessage,
    handleDeleteMessage,
    accountId,
    showModalInductionAccess,
    setShowModalInductionAccess,
    username
  } = useHome();

  const { events, getListEventCountry } = useEventCountry({
    indexCountry,
    country
  });

  useEffect(() => {
    axios
      .get(`${process.env.MYTHIC_GLOBAL_EVENT_URL}/country`)
      .then((result) => {
        const countryWithEvent = result.data.data.filter(
          (item: CountryType) => item.totalEvent > 0
        );

        setCountry(countryWithEvent);
        GlobeStore.handleSelectCountry(countryWithEvent[0]);
        getListEventCountry(countryWithEvent[0]?.id);
      });
  }, []);

  //Add riftstorm event
  useEffect(() => {
    setTimeout(() => {
      axios
        .get(
          `${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/json/riftstormMarker.json`
        )
        .then((res: any) => {
          const newData = res?.data;

          if (newData) {
            setCountry((prevCountry) => [
              ...prevCountry,
              ...newData.map((item: any) => ({
                id: item.id,
                markerPositionX: item.location.longitude,
                markerPositionY: item.location.latitude,
                name: item.dum_location_name,
                totalEvent: 1,
                type: item.type
              }))
            ]);
          }
        });
    }, 500);
  }, []);

  const getFactionName = async (token: string) => {
    try {
      const fetchFaction = await axios.get(
        `${process.env.MYTHIC_INDUCTION_HUB_V2_URL}user`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const { data } = fetchFaction as any;
      setFaction(data.data);
    } catch (err) {
      return null;
    }
  };

  const getCurrentSeason = async () => {
    try {
      const response = await axios.get(
        `${process.env.LEADERBOARD_URL}/leaderboard/seasonal`
      );
      const { data } = response as any;
      const datas = data.data;
      setSeasonData((prev) => ({
        ...prev,
        is_active: datas.is_active,
        season_start: datas.season_start,
        season_end: datas.season_end
      }));
    } catch (err) {
      return null;
    }
  };

  const getUserPointInformation = async (token: string) => {
    try {
      const response = await axios.get(
        `${process.env.URL_VALIDATION_SERVICES}user`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      const { data } = response as any;
      const results = data.data as {
        pointId: number;
        pointName: string;
        totalPoints: number;
        level: number;
        currentLevelPoint: number;
        requiredPointToNextLevel: number;
      }[];

      const filterResults = results.filter(
        (result) => result.pointName.toLowerCase() === 'clearance score'
      );
      const accountsLevel = results.filter(
        (result) => result.pointName.toLowerCase() === 'account'
      );

      setUserPointData((prev) => ({
        ...prev,
        pointId: filterResults[0].pointId,
        pointName: filterResults[0].pointName,
        totalPoints: filterResults[0].totalPoints,
        level: filterResults[0].level,
        currentLevelPoint: filterResults[0].currentLevelPoint,
        requiredPointToNextLevel: filterResults[0].requiredPointToNextLevel
      }));
      setAccountLevel(accountsLevel[0].level);
    } catch (err) {
      return null;
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      if (userToken) {
        getFactionName(userToken);
        getUserPointInformation(userToken);
      }
    }
  }, [isAuthenticated, userToken]);

  useEffect(() => {
    getCurrentSeason();
    ThreatIndexStore.getThreatIndex();
  }, []);

  useEffect(() => {
    if (isAuthenticated && faction) {
      setShowModalAssignment(false);
    } else if (!isAuthenticated || !faction) {
      setShowModalAssignment(true);
    }
  }, [isAuthenticated, faction]);

  const viewFooter = () => {
    if (width >= 1280) return <Footer />;
    if (width <= 1279)
      return (
        <div className={styles['footer-tablet']}>
          <Footer />
        </div>
      );

    return null;
  };

  useEffect(() => {
    const hasInductionAccess = localStorage.getItem('hasInductionAccess');

    if (hasInductionAccess) {
      setShowModalInductionAccess(false);
    } else if (!hasInductionAccess && faction) {
      setShowModalInductionAccess(true);
      localStorage.setItem('hasInductionAccess', 'true');
    }
  }, [faction]);

  const handleVideoLoading = useCallback(() => {
    if (isAuthenticated) {
      setShowVideoLoading(false);
    } else if (!isAuthenticated) {
      if (window.location.pathname !== '/data-deletion-instruction') {
        setShowVideoLoading(true);
      }
    }
  }, [isAuthenticated]);

  useEffect(() => {
    handleVideoLoading();
  }, [handleVideoLoading]);

  return (
    <div
      style={{
        backgroundImage: handleTargetBackgroundImg(width)
      }}
      ref={homepageRef}
      className={styles['container-full-screen']}
      data-testid="full-screen-globe"
    >
      {width >= 1280 && (
        <GlobeMythic
          listCountry={country}
          listEvent={events}
          setIndexCountry={setIndexCountry}
          scrollToHomepageTop={scrollToHomepageTop}
        />
      )}

      <div className={styles['container-left-content']} ref={tileRef}>
        {isTileContentAtTop && (
          <div
            className={styles['content-top-gradient']}
            style={{
              background:
                'linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0) 100%)'
            }}
          />
        )}
        <section className={styles['flowting-left__content']} ref={profileRef}>
          <div className={styles['content-wrapper']}>
            {faction && (
              <div className={styles['profile-card-wrapper']}>
                <CardConicGradient
                  username={username}
                  handleSignIn={signInHandle}
                  isAuthenticated={isAuthenticated}
                  profileImgUrl={ProfileStore.adminAccountData.picture}
                  faction={faction}
                  level={userPointData?.level}
                  requiredPointToNextLevel={
                    userPointData?.requiredPointToNextLevel
                  }
                  currentLevelPoint={userPointData?.currentLevelPoint}
                  tooltipRef={tooltipRef}
                  maxLevelPoint={
                    userPointData?.currentLevelPoint +
                    userPointData?.requiredPointToNextLevel
                  }
                  accountLevel={accountLevel}
                  onFactionIconClick={() => {
                    window.location.assign(
                      `${process.env.MYTHIC_INDUCTION_HUB_V2}/result` || ''
                    );
                  }}
                />
              </div>
            )}
            <div className={styles['section-card']}>
              <CardSectionWrapper
                title="ASSIGNMENT DASHBOARD"
                description="Earn EXP, Points, and Clearance"
                type="assignment"
                isLocked={!faction}
                onClick={() => {
                  window.open(process.env.URL_XPSR_QUEST, '_blank');
                }}
              />
            </div>
            <div className={styles['section-title']}>PROTOCOL INTIATIVES</div>
            <div className={styles['group-card-1']}>
              <div className={styles['section-card']}>
                <CardSectionWrapper
                  title="RIFTSTORM"
                  description="Looter-shooter Roguelite ARPG."
                  type="riftstorm"
                  isLocked={!faction}
                  onClick={() =>
                    window.open(process.env.WEB_RIFTSTORM_URL, '_blank')
                  }
                />
              </div>
              <div className={styles['section-card']}>
                <CardSectionWrapper
                  title="XPSR-24"
                  description="Track, surveil, and access operative-based assets."
                  type="xpsr"
                  isLocked={!faction}
                  onClick={() => {
                    window.open(process.env.URL_XPSR, '_blank');
                  }}
                />
              </div>
              <div className={styles['section-card']}>
                <CardSectionWrapper
                  title="REPOSITORY ACCESS"
                  description="Official database of mythics, dossiers,
                  and casefiles."
                  type="repository"
                  isLocked={!faction}
                  onClick={() => {
                    window.open(process.env.MYTHIC_REPOSITORY_URL, '_blank');
                  }}
                />
              </div>
            </div>
            <div className={styles['section-title']}>operational review</div>
            <div className={styles['group-card-1']}>
              <div className={styles['section-card']}>
                <CardSectionWrapper
                  title="OPERATION “DOGGY DAYCARE”"
                  description="C - 1787"
                  type="repository"
                  isLocked={!faction}
                  onClick={() => {
                    window.open(
                      `${process.env.HOST_URL}/doggy-daycare`,
                      '_blank'
                    );
                  }}
                />
              </div>
              <div className={styles['section-card']}>
                <CardSectionWrapper
                  title="OPERATION TOMBSTONE"
                  description="M - 0031"
                  type="repository"
                  isLocked={!faction}
                  onClick={() => {
                    window.open(
                      `${process.env.HOST_URL}/shade-resurgence`,
                      '_blank'
                    );
                  }}
                />
              </div>
            </div>
            <div className={styles['section-title']}>threat index</div>
            <div className={styles['group-card-1']}>
              <div className={styles['section-card']}>
                <CardSectionWrapper
                  title="threat index 2024"
                  description="Stay up to date with latest mythic threats."
                  type="repository"
                  isLocked={!faction}
                  onClick={() => {
                    window.open(process.env.URL_MYTHIC_ARG, '_blank');
                  }}
                />
              </div>
            </div>
            {width < 768 && <>{viewFooter()}</>}
            {width >= 1280 && <FooterDesktop />}
          </div>
        </section>
      </div>

      <div
        className={styles['back-to-top-arrow']}
        onClick={scrollToHomepageTop}
        onKeyDown={() => {
          return;
        }}
      >
        <img
          src={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/svg/icon-arrow-up-line.svg`}
          alt="arrow-up-icon"
        />
      </div>

      {width <= 1279 && (
        <div
          style={{
            position: 'relative',
            width: '100vw'
          }}
        >
          <GlobeMythic
            listCountry={country}
            listEvent={events}
            setIndexCountry={setIndexCountry}
            scrollToHomepageTop={scrollToHomepageTop}
          />
        </div>
      )}

      <div className={styles['floating-footer']}>
        <p className={styles['text1']}>Decentralized Universal Meta</p>
        <p className={styles['text2']}>BETA</p>
      </div>
      {width >= 768 && <>{viewFooter()}</>}

      <AchievementModal
        show={showModalAchievement}
        onClose={() => setShowModalAchievement(false)}
        AccountId={accountId}
      />
      <NotificationRedesign
        typeIcon="notification"
        onClose={() => setShowNotification(false)}
        show={showNotification}
        modalTitle="Announcements"
        data={dataNotices}
        unreadLength={unreadLengthNotice || 0}
        readLength={readLengthNotice || 0}
        updateStatus={updateStatusNotice}
        getStatusNotice={getStatusNotice}
        markAsRead={markAsReadNotice}
        updateArrayRemovedIdNotification={updateArrayRemovedIdNotice}
      />
      <NotificationMessage
        show={showModalMessages}
        onClose={() => setShowModalMessages(false)}
        data={mailboxData}
        modalTitle={'Messages'}
        unreadLength={unreadMailbox?.length || 0}
        readLength={readMailbox?.length || 0}
        markAsRead={markAsReadMessage}
        handleDeleteMessage={handleDeleteMessage}
      />
      <div className={styles['button-fab-wrapper']}>
        <FabGroupButton
          isLocked={!faction}
          onAchievementClick={() => setShowModalAchievement(true)}
          onNotificationClick={() => setShowNotification(true)}
          onMessageClick={() => setShowModalMessages(true)}
        />
      </div>
      <ModalAssignment
        show={showModalAssignment}
        numberStepActive={
          !isAuthenticated
            ? 1
            : isAuthenticated && !faction
            ? 2
            : isAuthenticated && faction
            ? 3
            : 0
        }
      />
      <ModalGrantedInduction
        show={showModalInductionAccess}
        onClose={() => setShowModalInductionAccess(false)}
      />
      <PortalOpeningVideo
        show={showVideoLoading}
        onEnded={() => {
          setShowVideoLoading(false);
        }}
      />
      <Tooltip
        id="factionTooltip"
        place={width < 1280 ? 'left-end' : 'right-end'}
        style={{
          color: '#E4E4E4',
          fontFamily: 'Barlow',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '24px',
          background: '#0C0F12',
          textTransform: 'uppercase',
          zIndex: 9999
        }}
      >
        {faction?.factionName}
      </Tooltip>
      <Tooltip
        id="my-tooltip-clr-level"
        place="bottom-end"
        role="tooltip"
        style={{
          color: '#E4E4E4',
          background: '#0C0F12',
          fontFamily: `Barlow`,
          fontStyle: 'normal',
          fontSize: '14px',
          fontWeight: '400',
          width: '244px',
          zIndex: 9999
        }}
      >
        Complete assignments to earn Clearance Score to increase your Clearance
        Level.
      </Tooltip>
    </div>
  );
});

export default withStore(FullScreenGlobe);
