// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LRRJJa5yl8mu3qiJi9Vg{display:flex;flex-direction:column;align-items:center;justify-content:center;flex-direction:column;justify-content:center;align-items:center;width:100%;height:100vh;max-height:100%;position:fixed;left:0;top:0;z-index:1500;background:rgba(2,26,46,.8)}.o_R3qWv5BURrBYAkunaz{margin-top:1rem}`, "",{"version":3,"sources":["webpack://./src/Components/Atoms/loadingScreen/styles.module.scss"],"names":[],"mappings":"AAAA,sBACI,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,qBAAA,CACA,sBAAA,CACA,kBAAA,CACA,UAAA,CACA,YAAA,CACA,eAAA,CACA,cAAA,CACA,MAAA,CACA,KAAA,CACA,YAAA,CACA,2BAAA,CAGJ,sBACI,eAAA","sourcesContent":[".body {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 100vh;\n    max-height: 100%;\n    position: fixed;\n    left: 0;\n    top: 0;\n    z-index: 1500;\n    background: rgba(2, 26, 46, 0.8);\n}\n\n.label {\n    margin-top: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `LRRJJa5yl8mu3qiJi9Vg`,
	"label": `o_R3qWv5BURrBYAkunaz`
};
export default ___CSS_LOADER_EXPORT___;
