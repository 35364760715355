import React from 'react';
import styles from './PlaytestResult.module.scss';
import useWindowDimensions from '@src/Common/Hooks/useWindowDimensions';

const PlaytestResult = () => {
  const { width } = useWindowDimensions();

  const handleTableImg = () => {
    if (width < 1920 && width >= 1280) {
      return 'table-1280.png';
    } else if (width < 1280 && width >= 768) {
      return 'table-768.png';
    } else if (width < 768) {
      return 'table-375.png';
    } else {
      return 'table.png';
    }
  };

  const handleRiftstormCharacter = () => {
    if (width < 1280 && width >= 768) {
      return 'riftstorm-character-768.png';
    } else if (width < 768) {
      return 'riftstorm-character-375.png';
    } else {
      return 'riftstorm-character.png';
    }
  };

  const handleLeftBorderBg = () => {
    if (width < 1280 && width >= 768) {
      return 'left-border-bg-768.png';
    } else if (width < 768) {
      return 'left-border-bg-375.png';
    } else {
      return 'left-border-bg.png';
    }
  };

  const handleRightBorderBg = () => {
    if (width < 1280 && width >= 768) {
      return 'right-border-bg-768.png';
    } else if (width < 768) {
      return 'right-border-bg-375.png';
    } else {
      return 'right-border-bg.png';
    }
  };

  const handleRiftstormText = () => {
    if (width < 1280 && width >= 768) {
      return 'riftstorm-text-768.png';
    } else if (width < 768) {
      return 'riftstorm-text-375.png';
    } else {
      return 'riftstorm-text.png';
    }
  };

  return (
    <div className={styles['container']}>
      <div className={styles['riftstorm-character-wrapper']}>
        <div className={styles['riftstorm-character-image-wrapper']}>
          <img
            src={`${
              process.env.REACT_APP_GCM_BUCKET_URL
            }/mythic-portal-v2/img/png/playtest_result/${handleRiftstormCharacter()}`}
            alt="riftstorm-character"
          />
        </div>
        <div className={styles['riftstorm-character-gradient-wrapper']}></div>
      </div>
      <img
        src={`${
          process.env.REACT_APP_GCM_BUCKET_URL
        }/mythic-portal-v2/img/png/playtest_result/${handleLeftBorderBg()}`}
        alt="border"
        className={styles['left-border-bg']}
      />
      <img
        src={`${
          process.env.REACT_APP_GCM_BUCKET_URL
        }/mythic-portal-v2/img/png/playtest_result/${handleRightBorderBg()}`}
        alt="border"
        className={styles['right-border-bg']}
      />
      <div className={styles['top-bg-wrapper']}>
        <img
          src={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/playtest_result/top-bg-new.png`}
          alt="border"
          className={styles['top-bg']}
        />
      </div>

      <div className={styles['bottom-bg-wrapper']}>
        <img
          src={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/playtest_result/bottom-bg-new.png`}
          alt="border"
          className={styles['bottom-bg']}
        />
      </div>

      <div className={styles['content-wrapper']}>
        <div className={styles['riftstorm-text-wrapper']}>
          <img
            src={`./assets/img/png/${handleRiftstormText()}`}
            alt="riftstorm-text"
          />
        </div>
        <div className={styles['title']}>
          <p>A ROARING SUCCESS</p>
          <p>PC STEAM</p>
        </div>
        <div className={styles['video-container']}>
          <img
            src={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/playtest_result/yellow-character1.png`}
            alt="yellow-character"
            className={styles['yellow-character1']}
          />
          <img
            src={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/playtest_result/yellow-character2.png`}
            alt="yellow-character"
            className={styles['yellow-character2']}
          />
          <iframe
            width="640"
            height="390"
            src={
              'https://www.youtube.com/embed/eNXTG3XiB7o?si=7K71lVNS5uPqXxab'
            }
            title="YouTube Video Player"
            allowFullScreen
          ></iframe>
        </div>
        <div className={styles['table-wrapper']}>
          <img
            src={`${
              process.env.REACT_APP_GCM_BUCKET_URL
            }/mythic-portal-v2/img/png/playtest_result/${handleTableImg()}`}
            alt="table"
            className={styles['table-img']}
          />
          <img
            src={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/playtest_result/yellow-character3.png`}
            alt="yellow-character"
            className={styles['yellow-character3']}
          />
        </div>
        <div className={styles['table-text-wrapper']}>
          <p className={styles['first-text']}>
            Note that the team only counts every player’s first attempts as the
            current end-game loot table involves farming the mission for the few
            who can complete it.{' '}
          </p>
          <p className={styles['second-text']}>
            The team is looking forward to welcoming new players into the fray
            as the playtest continues until March 15, 19:00 PST.
          </p>
        </div>
        <div className={styles['title2']}>
          <p>YOUR ACTIONS, WORLDWIDE CONSEQUENCES.</p>
        </div>
        <div className={styles['hero-wrapper']}>
          <img
            src={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/playtest_result/hero-playtest-result.png`}
            alt="hero"
          />
        </div>
        <div className={styles['tombstone-wrapper']}>
          <p className={styles['tombston-title']}>OP. TOMBSTONE</p>
          <p className={styles['tombstone-text']}>
            The fate of the world is your hands. All Operation Tombstone
            attempts will be recorded, and your successes or failures will
            dictate the future of the mythic threat posed by the Shade
            resurgence.
          </p>
          <p className={styles['alert-text']}>MISSED THE BRIEFING?</p>
          <div className={styles['button-wrapper']}>
            <div
              className={styles['button']}
              onClick={() => {
                window.open(
                  `${process.env.MYTHIC_REPOSITORY_URL}/M-0031`,
                  '_blank'
                );
              }}
            >
              read the casefile
            </div>
            <div
              className={styles['button']}
              onClick={() => {
                window.open(
                  `${process.env.MYTHIC_PROTOCOL_URL}shade-resurgence`,
                  '_blank'
                );
              }}
            >
              read the comic
            </div>
          </div>
          <img
            src={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/playtest_result/ghost-1920.png`}
            alt="ghost"
            className={styles['ghost-image']}
          />
        </div>
      </div>
    </div>
  );
};

export default PlaytestResult;
