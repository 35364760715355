import React from 'react';
import styles from './ShadeResurgence.module.scss';
import ButtonDefault from '@src/Components/Atoms/ButtonDefault/ButtonDefault';
import ShadeResurgenceComic from '@src/Components/Organisms/ShadeResurgence/ShadeResurgenceComic/ShadeResurgenceComic';
import useWindowDimensions from '@src/Common/Hooks/useWindowDimensions';
import dayjs from 'dayjs';

const ShadeResurgence = () => {
  const { width } = useWindowDimensions();

  const handleBackgroundAssets = () => {
    if (width >= 1920) {
      return 'background.png';
    } else if (width >= 1280 && width < 1920) {
      return 'background-1280.png';
    } else if (width >= 768 && width < 1280) {
      return 'background-768.png';
    } else if (width < 768) {
      return 'background-375.png';
    }
  };

  const handleHero = () => {
    if (width >= 1920) {
      return 'hero.png';
    } else if (width >= 1280 && width < 1920) {
      return 'hero-1280.png';
    } else if (width >= 768 && width < 1280) {
      return 'hero-768.png';
    } else if (width < 768) {
      return 'hero-375.png';
    }
  };

  const handleProgressBar = () => {
    if (width >= 1920) {
      return 'progress-bar.png';
    } else if (width >= 1280 && width < 1920) {
      return 'progress-bar-1280.png';
    } else if (width >= 768 && width < 1280) {
      return 'progress-bar-768.png';
    } else if (width < 768) {
      return 'progress-bar-375.png';
    }
  };

  const handleBackground2Assets = () => {
    if (width >= 1920) {
      return 'background2.png';
    } else if (width >= 1280 && width < 1920) {
      return 'background2-1280.png';
    } else if (width >= 768 && width < 1280) {
      return 'background2-768.png';
    } else if (width < 768) {
      return 'background2-375.png';
    }
  };

  const handleGhostImage = () => {
    if (width >= 1920) {
      return 'ghost.png';
    } else if (width >= 1280 && width < 1920) {
      return 'ghost-1280.png';
    } else if (width >= 768 && width < 1280) {
      return 'ghost-768.png';
    } else if (width < 768) {
      return 'ghost-375.png';
    }
  };

  const currentYear = dayjs().year();
  return (
    <>
      <main className={styles['Container']} data-testid="main-container">
        <div
          className={styles['section1a']}
          style={{
            color: 'black',
            backgroundImage: `url(${
              process.env.REACT_APP_GCM_BUCKET_URL
            }/mythic-portal-v2/img/png/shade_resurgence/${handleBackgroundAssets()})`
          }}
        >
          <div className={styles['section1a-content-wrapper']}>
            <div className={styles['title']}>
              flashpoint event: <br /> shade resurgence
            </div>
            <div className={styles['hero-wrapper']}>
              <img
                src={`${
                  process.env.REACT_APP_GCM_BUCKET_URL
                }/mythic-portal-v2/img/png/shade_resurgence/${handleHero()}`}
                alt="hero"
              />
            </div>
            <div className={styles['progress-bar-wrapper']}>
              <img
                src={`${
                  process.env.REACT_APP_GCM_BUCKET_URL
                }/mythic-portal-v2/img/png/shade_resurgence/${handleProgressBar()}`}
                alt="progress-bar"
              />
              <div className={styles['progress-bar-text']}>
                <div>
                  81% <br /> <span>FAILURE</span>
                </div>
                <div>
                  19% <br /> <span>SUCCESS</span>
                </div>
              </div>
            </div>
            <div className={styles['tombstone-text-wrapper']}>
              <p className={styles['main-text']}>op. tombstone</p>
              <p className={styles['paragraph']}>
                The fate of the world is your hands. All Operation Tombstone
                attemps will be recorded, and your successes failures will
                dictate the future of the mythic threat posed by the Shade
                resurgences.
              </p>
            </div>
          </div>
        </div>
        <div className={styles['section1b']}>
          <div className={styles['paragraph-wrapper']}>
            <p className={styles['paragraph']}>
              {width >= 1920 && (
                <>
                  M-0031 is the designation given to a phantasmal entity <br />{' '}
                  whose touch is fatal to humans. A shade-touched human, when
                  dead, will give rise to another M-0031. The incarnation of the
                  original shade is only half-understood.
                </>
              )}
              {width < 1920 && (
                <>
                  M-0031 is the designation given to a phantasmal entity whose
                  touch is fatal to humans. A shade-touched human, when dead,
                  will give rise to another M-0031. The incarnation of the
                  original shade is only half-understood.
                </>
              )}
            </p>
          </div>
        </div>
        <div
          className={styles['section1c']}
          style={{
            backgroundImage: `url(${
              process.env.REACT_APP_GCM_BUCKET_URL
            }/mythic-portal-v2/img/png/shade_resurgence/${handleBackground2Assets()})`
          }}
        >
          <div className={styles['shadow']}></div>
          <div className={styles['shadow2']}></div>
          <div className={styles['section1c-content-wrapper']}>
            <img
              src={`${
                process.env.REACT_APP_GCM_BUCKET_URL
              }/mythic-portal-v2/img/png/shade_resurgence/${handleGhostImage()}`}
              alt="ghost-image"
            />
            <p className={styles['badge']}>spectral</p>
            <div className={styles['content-wrapper']}>
              <div className={styles['left-content']}>
                <div className={styles['first-row']}>
                  <div className={styles['icon-wrapper']}>
                    <img
                      src={`${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/shade_resurgence/book-icon.png`}
                      alt="book-icon"
                    />
                  </div>
                  <div className={styles['text']}>
                    You can read Casefile: M-0031 <br /> and explore our growing{' '}
                    <br /> universe at the Repository.
                  </div>
                </div>
                <div className={styles['second-row']}>
                  <div className={styles['button-wrapper']}>
                    <ButtonDefault
                      text="Read Casefile M-0031 "
                      icon="./assets/img/svg/learn-more-icon.svg"
                      onClick={() => {
                        window.open(
                          'https://repository.mythicprotocol.com/M-0031',
                          '_blank'
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={styles['right-content']}>
                <p> M-0031</p>
                <p>the ward</p>
              </div>
            </div>
          </div>
        </div>
        {/* Second Section */}
        <section className={styles['Section2']}>
          <ShadeResurgenceComic />
        </section>
      </main>

      <footer>
        <h4>© {currentYear} Confiction Labs. All rights reserved.</h4>
        <a
          href={`${process.env.HOST_URL}/privacy-policy`}
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
      </footer>
    </>
  );
};

export default ShadeResurgence;
