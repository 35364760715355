// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xGdAsGhVK5DwCKFp1P2t{pointer-events:none;margin-bottom:10px}.xGdAsGhVK5DwCKFp1P2t div{background:var(--primary-navy-300);width:auto;display:flex;justify-content:center;align-items:center}.xGdAsGhVK5DwCKFp1P2t div div{width:85px}@media screen and (max-width: 1279px){.xGdAsGhVK5DwCKFp1P2t div div{width:auto;max-width:200px}}.xGdAsGhVK5DwCKFp1P2t div img{border:1px solid var(--secondary-tosca-300)}`, "",{"version":3,"sources":["webpack://./src/Components/Organisms/ResponsiveAchivementTab/ProfileResponsive/ProfileResponsive.module.scss"],"names":[],"mappings":"AAAA,sBACE,mBAAA,CACA,kBAAA,CAEA,0BACE,kCAAA,CACA,UAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CAEA,8BACE,UAAA,CAEA,sCAHF,8BAII,UAAA,CACA,eAAA,CAAA,CAIJ,8BACE,2CAAA","sourcesContent":[".content-profile-card {\n  pointer-events: none;\n  margin-bottom: 10px;\n\n  div {\n    background: var(--primary-navy-300);\n    width: auto;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    div {\n      width: 85px;\n\n      @media screen and (max-width: 1279px) {\n        width: auto;\n        max-width: 200px;\n      }\n    }\n\n    img {\n      border: 1px solid var(--secondary-tosca-300);\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content-profile-card": `xGdAsGhVK5DwCKFp1P2t`
};
export default ___CSS_LOADER_EXPORT___;
