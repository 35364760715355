import DoggyComicPagination from '@src/Components/Molecules/Doggy/DoggyComicPagination/DoggyComicPagination';
import React, { useState } from 'react';
import styles from './DoggyComic.module.scss';
import DoggyComicCarousel from '@src/Components/Molecules/Doggy/DoggyComicCarousel/DoggyComicCarousel';
const images = [
  `${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/doggy/comic/Cover.jpg`,
  `${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/doggy/comic/DD_Post Playtest_Page01_Lettered.jpg`,
  `${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/doggy/comic/DD_Post Playtest_Page02_Lettered.jpg`,
  `${process.env.REACT_APP_GCM_BUCKET_URL}/mythic-portal-v2/img/png/doggy/comic/DD_Post Playtest_Page03_Lettered.jpg`
];

const DoggyComic = () => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  return (
    <div className={styles['Container']}>
      <DoggyComicPagination
        totalPage={images.length}
        activeIndex={currentIndex}
        onChangePage={(page: number) => {
          //
          setCurrentIndex(page);
        }}
      />
      <DoggyComicCarousel images={images} current={currentIndex} />
      <DoggyComicPagination
        totalPage={images.length}
        activeIndex={currentIndex}
        onChangePage={(page: number) => {
          //
          setCurrentIndex(page);
        }}
      />
    </div>
  );
};

export default DoggyComic;
