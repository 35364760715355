import React from 'react';
import styles from './ModalAssignment.module.scss';
import StepItem from '@src/Components/Atoms/StepItem/StepItem';
import ButtonDefault from '@src/Components/Atoms/ButtonDefault/ButtonDefault';
import { useAuth } from 'react-oidc-context';

type IModalAssignment = {
  numberStepActive?: number;
  show: boolean;
};

const ModalAssignment: React.FC<IModalAssignment> = ({
  numberStepActive = 2,
  show = false
}) => {
  const { signinPopup } = useAuth();

  const handleSignIn = () => {
    signinPopup();
  };
  return (
    <>
      {show && (
        <div className={styles['main-container']}>
          <div className={styles['drop-shadow']}></div>
          <div className={styles['container']}>
            <div className={styles['inner-container']}>
              <div className={styles['image-wrapper']}>
                <img src="assets/img/svg/induction-icon.svg" alt="image" />
              </div>
              <div className={styles['description']}>
                <p>
                  ACCESS DENIED: PLEASE COMPLETE <br /> THE REQUISITE CHECKS FOR
                  ENTRY
                </p>
              </div>
              <div className={styles['step-container']}>
                <p className={styles['main-step-title']}>Requirements</p>
                <div className={styles['step-wrapper']}>
                  <div className={styles['left-content']}>
                    <div className={styles['step-item']}>
                      <StepItem
                        isActive={numberStepActive === 1}
                        isPassed={numberStepActive > 1}
                      />
                    </div>
                    <div className={styles['vertical-line']} />
                    <div className={styles['step-item']}>
                      <StepItem
                        isActive={numberStepActive === 2}
                        isPassed={numberStepActive > 2}
                      />
                    </div>
                    <div className={styles['vertical-line']} />
                    <div className={styles['step-item']}>
                      <StepItem
                        isActive={numberStepActive >= 3}
                        isPassed={numberStepActive >= 3}
                      />
                    </div>
                  </div>
                  <div className={styles['right-content']}>
                    <div className={styles['step-text']}>
                      <p className={styles['step-title']}>
                        SIGN-IN TO YOUR <br /> PORTAL ACCOUNT
                      </p>
                    </div>
                    <div className={styles['step-text']}>
                      <p className={styles['step-title']}>
                        COMPLETE PROTOCOL <br /> INDUCTION EXAM
                      </p>
                      <p className={styles['step-description']}>
                        Note: Completion is Required for Protocol Clearance
                      </p>
                    </div>
                    <div className={styles['step-text']}>
                      <p
                        className={`${styles['step-title']} ${styles['third']}`}
                      >
                        SUBMIT PROOF OF HUMANITY
                      </p>
                      <p className={styles['step-description']}>
                        Note: Completion is Required for <br />
                        <span
                          onClick={() =>
                            window.open(process.env.URL_XPSR, '_blank')
                          }
                        >
                          XPSR-24
                        </span>{' '}
                        Access
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles['button-wrapper']}>
                <div className={styles['button']}>
                  {numberStepActive < 3 && (
                    <ButtonDefault
                      text={
                        numberStepActive === 1
                          ? 'Login'
                          : numberStepActive === 2
                          ? 'Complete the Protocol Induction'
                          : ''
                      }
                      onClick={() => {
                        if (numberStepActive === 1) {
                          handleSignIn();
                        } else if (numberStepActive === 2) {
                          window.location.assign(
                            process.env.MYTHIC_INDUCTION_HUB_V2 || ''
                          );
                        }
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalAssignment;
