import React from 'react';
import styles from './GameLiveTiles.module.scss';
import useWindowDimensions from '@src/Common/Hooks/useWindowDimensions';
import { logEvent } from '@src/Domain/analytic';
import { AnalyticsEvent } from '@src/Domain/analytic/analytic.type';

import ButtonLiveTiles from '@src/Components/Atoms/Button/ButtonLiveTiles/ButtonLiveTiles';

const GameLiveTiles = () => {
  const { width } = useWindowDimensions();

  const steamURL = 'steam://store/2282790';
  const steamWebsiteURL =
    'https://store.steampowered.com/app/2282790/RIFTSTORM/';

  const openSteam = () => {
    window.location.href = steamURL;
    logEvent(AnalyticsEvent.LINK_VISITED, 'Steam Riftstorm Live Tiles Click');
    setTimeout(() => {
      window.open(steamWebsiteURL, '_blank');
    }, 1000);
  };

  const handleBg = () => {
    if (width < 768 || width >= 1280) {
      return 'riftstorm-mobile.png';
    } else {
      return 'riftstorm-tablet.png';
    }
  };

  return (
    <div
      className={styles['container']}
      style={{
        backgroundImage: `linear-gradient(89deg, rgba(156, 14, 77, 0.2) 11.14%, rgba(22, 22, 41, 0) 103.09%), url(./assets/img/png/${handleBg()})`
      }}
      data-testid="game-live-tiles"
    >
      <div className={styles['content-wrapper']}>
        <div className={styles['button-wrapper']}>
          <ButtonLiveTiles
            color="pink"
            onClick={() =>
              window.open(`${process.env.URL_XPSR_QUEST}`, '_blank')
            }
          />
        </div>
        <div className={styles['button-wrapper']}>
          <ButtonLiveTiles color="black" onClick={openSteam} />
        </div>
      </div>
    </div>
  );
};

export default GameLiveTiles;
