// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gFwO0wzxLhJRMcvPnNOY{min-height:768px}.gFwO0wzxLhJRMcvPnNOY img{width:100%}@media screen and (max-width: 767px){.gFwO0wzxLhJRMcvPnNOY{min-height:628px}}`, "",{"version":3,"sources":["webpack://./src/Components/Molecules/Doggy/DoggyComicCarousel/DoggyComicCarousel.module.scss"],"names":[],"mappings":"AAAA,sBACI,gBAAA,CAEA,0BACI,UAAA,CAKR,qCACI,sBACI,gBAAA,CAAA","sourcesContent":[".Content {\n    min-height: 768px;\n\n    img {\n        width: 100%;\n\n    }\n}\n\n@media screen and (max-width: 767px) {\n    .Content {\n        min-height: 628px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Content": `gFwO0wzxLhJRMcvPnNOY`
};
export default ___CSS_LOADER_EXPORT___;
