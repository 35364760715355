import React from 'react';
import styles from './OPTombstone.module.scss';

import useWindowDimensions from '@src/Common/Hooks/useWindowDimensions';
import dayjs from 'dayjs';
import ButtonDefault from '@src/Components/Atoms/ButtonDefault/ButtonDefault';
import ComicReader from '@src/Components/Organisms/ComicReader/ComicReader';
import { tombstoneComicImages } from './OPTombstone.util';

const OPTombstone = () => {
  const { width } = useWindowDimensions();

  const handleBackgroundAssets = () => {
    if (width >= 1920) {
      return 'background.png';
    } else if (width >= 1280 && width < 1920) {
      return 'background-1280.png';
    } else if (width >= 768 && width < 1280) {
      return 'background-768.png';
    } else if (width < 768) {
      return 'background-375.png';
    }
  };

  const handleHero = () => {
    if (width >= 1920) {
      return 'hero.png';
    } else if (width >= 1280 && width < 1920) {
      return 'hero-1280.png';
    } else if (width >= 768 && width < 1280) {
      return 'hero-768.png';
    } else if (width < 768) {
      return 'hero-375.png';
    }
  };

  const handleProgressBar = () => {
    if (width >= 1920) {
      return 'progress-bar.png';
    } else if (width >= 1280 && width < 1920) {
      return 'progress-bar-1280.png';
    } else if (width >= 768 && width < 1280) {
      return 'progress-bar-768.png';
    } else if (width < 768) {
      return 'progress-bar-375.png';
    }
  };

  const currentYear = dayjs().year();

  return (
    <>
      <main className={styles['Container']} data-testid="main-container">
        <div
          className={styles['section1a']}
          style={{
            color: 'black',
            backgroundImage: `url(${
              process.env.REACT_APP_GCM_BUCKET_URL
            }/mythic-portal-v2/img/png/shade_resurgence/${handleBackgroundAssets()})`
          }}
        >
          <div className={styles['section1a-content-wrapper']}>
            <div className={styles['title']}>
              flashpoint event: <br /> shade resurgence
            </div>
            <div className={styles['hero-wrapper']}>
              <img
                src={`${
                  process.env.REACT_APP_GCM_BUCKET_URL
                }/mythic-portal-v2/img/png/shade_resurgence/${handleHero()}`}
                alt="hero"
              />
            </div>
            <div className={styles['progress-bar-wrapper']}>
              <img
                src={`${
                  process.env.REACT_APP_GCM_BUCKET_URL
                }/mythic-portal-v2/img/png/shade_resurgence/${handleProgressBar()}`}
                alt="progress-bar"
              />
              <div className={styles['progress-bar-text']}>
                <div>
                  81% <br /> <span>FAILURE</span>
                </div>
                <div>
                  19% <br /> <span>SUCCESS</span>
                </div>
              </div>
            </div>
            <div className={styles['tombstone-text-wrapper']}>
              <p className={styles['main-text']}>op. tombstone</p>
              <p className={styles['paragraph']}>
                The fate of the world is your hands. All Operation Tombstone
                attemps will be recorded, and your successes failures will
                dictate the future of the mythic threat posed by the Shade
                resurgences.
              </p>
            </div>
          </div>
        </div>
        <div className={styles['section1b']}>
          <div className={styles['paragraph-wrapper']}>
            <div className={styles['paragraph-description-container']}>
              <div className={styles['paragraph-description']}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="60"
                  height="77"
                  viewBox="0 0 60 77"
                  fill="none"
                >
                  <path
                    d="M10.3125 77C7.5 77 5.07812 76.0769 3.04688 74.2305C1.01562 72.3842 0 70.0991 0 67.375V16.7075C0 14.4072 0.734374 12.3491 2.20312 10.533C3.67187 8.71698 5.59375 7.56682 7.96875 7.08255L45 0V58.1132L9.46875 65.0141C8.90625 65.1352 8.4375 65.4228 8.0625 65.8768C7.6875 66.3308 7.5 66.8302 7.5 67.375C7.5 68.0409 7.78125 68.6008 8.34375 69.0548C8.90625 69.5089 9.5625 69.7359 10.3125 69.7359H52.5V11.6226H60V77H10.3125ZM11.25 57.296L18.75 55.8432V12.4399L11.25 13.8927V57.296Z"
                    fill="#C4964D"
                  />
                </svg>
                <div
                  style={{
                    height: '100%',
                    width: '2px',
                    background: '#D9D9D9',
                    flexShrink: 0
                  }}
                ></div>
                <p>
                  You can read Casefile: C-0268 <br /> and explore our growing{' '}
                  <br />
                  universe at the Repository.
                </p>
              </div>
              <div className={styles['second-row']}>
                <div className={styles['button-wrapper']}>
                  <ButtonDefault
                    text="Read The Entry"
                    icon="./assets/img/svg/learn-more-icon.svg"
                    onClick={() => {
                      window.open(
                        process.env.URL_MYTHIC_REPOSITORY + '/C-0268'
                      );
                    }}
                  />
                </div>
              </div>
            </div>
            <p className={styles['paragraph']}>
              {width >= 1920 && (
                <>
                  C-0268 is the designation given to a phantasmal entity <br />{' '}
                  whose touch is fatal to humans. A shade-touched human, when
                  dead, will give rise to another C-0268. The incarnation of the
                  original shade is only half-understood.
                </>
              )}
              {width < 1920 && (
                <>
                  C-0268 is the designation given to a phantasmal entity whose
                  touch is fatal to humans. A shade-touched human, when dead,
                  will give rise to another C-0268. The incarnation of the
                  original shade is only half-understood.
                </>
              )}
            </p>
          </div>
        </div>
        {/* Second Section */}
        <section id="comic" className={styles['Section2']}>
          <ComicReader images={tombstoneComicImages} />
        </section>
      </main>

      <footer>
        <h4>© {currentYear} Confiction Labs. All rights reserved.</h4>
        <a
          href={`${process.env.HOST_URL}/privacy-policy`}
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
      </footer>
    </>
  );
};

export default OPTombstone;
