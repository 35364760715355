import React, { useState } from 'react';
import styles from './DoggyComicPagination.module.scss';
import DoggyComicArrowLeft from '@src/Components/Atoms/Doggy/DoggyComicArrowLeft/DoggyComicArrowLeft';
import DoggyComicArrowRight from '@src/Components/Atoms/Doggy/DoggyComicArrowRight/DoggyComicArrowRight';
const DoggyComicPagination: React.FC<{
  totalPage: number;
  activeIndex: number;
  onChangePage: (page: number) => void;
}> = ({ totalPage, activeIndex, onChangePage }) => {
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  return (
    <div className={styles['Container']} data-testid="doggy-comic-pagination">
      <button
        className={styles['Arrow']}
        onClick={() => {
          if (activeIndex > 0) {
            onChangePage(activeIndex - 1);
          }
        }}
      >
        <DoggyComicArrowLeft />
      </button>

      <div className={styles['PagingContainer']}>
        {Array.from({ length: totalPage }, (_v, k) => (
          <button
            onClick={() => onChangePage(k)}
            className={activeIndex === k ? styles['active'] : ''}
            key={k}
          >
            {k === 0 ? 'Cover' : k}
          </button>
        ))}
      </div>

      {/* Only active on mobile */}
      <div className={styles['PagingDropdown']}>
        <button onClick={() => setOpenDropdown(!openDropdown)}>
          <span>{activeIndex === 0 ? 'Cover' : activeIndex}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
          >
            <mask
              id="mask0_203_770"
              style={{ maskType: 'alpha' }}
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="19"
              height="18"
            >
              <rect x="0.5" width="18" height="18" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_203_770)">
              <path
                d="M9.5 11.5312L5 7.0312L6.05 5.9812L9.5 9.4312L12.95 5.9812L14 7.0312L9.5 11.5312Z"
                fill="#E4E4E4"
              />
            </g>
          </svg>
        </button>

        <ul
          className={
            styles['Dropdown'] + ' ' + (openDropdown ? styles['active'] : '')
          }
        >
          {Array.from({ length: totalPage }, (_v, k) => (
            <li key={k}>
              <button
                className={activeIndex === k ? styles['active'] : ''}
                onClick={() => {
                  onChangePage(k);
                  setOpenDropdown(!openDropdown);
                }}
              >
                <span>{k === 0 ? 'Cover' : k}</span>
              </button>
            </li>
          ))}
        </ul>
      </div>
      <button
        className={styles['Arrow']}
        onClick={() => {
          if (activeIndex < totalPage - 1) {
            onChangePage(activeIndex + 1);
          }
        }}
      >
        <DoggyComicArrowRight />
      </button>
    </div>
  );
};

export default DoggyComicPagination;
