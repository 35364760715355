// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZQoD877HphILn8vc3pQe{width:100vw;height:100vh;box-sizing:border-box;position:fixed;top:0;left:0;z-index:9999;background:#000;display:flex;justify-content:center;align-items:center}.ZQoD877HphILn8vc3pQe .vtKaDTKfgXPmDxHIaKrx{width:100%;height:100%;box-sizing:border-box;display:flex;justify-content:center;align-items:center;will-change:transform,opacity;transform:translateZ(0);backface-visibility:hidden;overflow:hidden}.ZQoD877HphILn8vc3pQe .vtKaDTKfgXPmDxHIaKrx video{transform:translateZ(0);backface-visibility:hidden}@media screen and (min-width: 1920px){.ZQoD877HphILn8vc3pQe .vtKaDTKfgXPmDxHIaKrx{width:1920px}}`, "",{"version":3,"sources":["webpack://./src/Components/Molecules/PortalOpeningVideo/PortalOpeningVideo.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,YAAA,CACA,qBAAA,CAEA,cAAA,CACA,KAAA,CACA,MAAA,CACA,YAAA,CAEA,eAAA,CAEA,YAAA,CACA,sBAAA,CACA,kBAAA,CAEA,4CACE,UAAA,CACA,WAAA,CACA,qBAAA,CAEA,YAAA,CACA,sBAAA,CACA,kBAAA,CAEA,6BAAA,CACA,uBAAA,CACA,0BAAA,CACA,eAAA,CAEA,kDACE,uBAAA,CACA,0BAAA,CAKN,sCAEI,4CACE,YAAA,CAAA","sourcesContent":[".container {\n  width: 100vw;\n  height: 100vh;\n  box-sizing: border-box;\n\n  position: fixed;\n  top: 0;\n  left: 0;\n  z-index: 9999;\n\n  background: #000000;\n\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  .video-wrapper {\n    width: 100%;\n    height: 100%;\n    box-sizing: border-box;\n\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    will-change: transform, opacity;\n    transform: translateZ(0);\n    backface-visibility: hidden;\n    overflow: hidden;\n\n    video {\n      transform: translateZ(0);\n      backface-visibility: hidden;\n    }\n  }\n}\n\n@media screen and (min-width: 1920px) {\n  .container {\n    .video-wrapper {\n      width: 1920px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `ZQoD877HphILn8vc3pQe`,
	"video-wrapper": `vtKaDTKfgXPmDxHIaKrx`
};
export default ___CSS_LOADER_EXPORT___;
