// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MDpSJRdQjtIFI4tBxLaR{width:140px;cursor:pointer;height:100%;box-sizing:border-box;border-radius:4px;box-shadow:0px 4px 4px 0px rgba(0,0,0,.25)}.gfv8EUIQqlcEmgouuueP{border:1px solid var(--Secondary-Magenta-300, #cd1c6a);background:var(--Secondary-Magenta-400, #a41655)}.gfv8EUIQqlcEmgouuueP:hover{background:var(--Secondary-Magenta-300, #cd1c6a)}.y6GejuR0tKIyql21wCB2{border:1px solid var(--Secondary-Magenta-300, #cd1c6a);background:var(--Secondary-Magenta-400, #a41655)}.y6GejuR0tKIyql21wCB2:hover{background:var(--Secondary-Magenta-300, #cd1c6a)}.iwX6wbahLA3c59l_iTQh{border:1px solid var(--Neutral-Black-100, #232a31);background:var(--Neutral-Black-200, #101418)}.iwX6wbahLA3c59l_iTQh:hover{background:var(--Neutral-Black-100, #232a31)}.MDpSJRdQjtIFI4tBxLaR .vhiW5z_CwRgIEU9kpIbo{display:flex;justify-content:center;align-items:center;column-gap:8px}.MDpSJRdQjtIFI4tBxLaR .vhiW5z_CwRgIEU9kpIbo .NSkw7IwkdtoDuBm5U53s{width:12px;height:12px;display:flex;justify-content:center;align-items:center;box-sizing:border-box;flex-shrink:0}.MDpSJRdQjtIFI4tBxLaR .vhiW5z_CwRgIEU9kpIbo .wo2z_j71lQPwVs2wQ5PL{color:var(--Neutral-White-100, #fff);text-align:center;font-family:"Barlow",sans-serif;font-size:12px;font-style:normal;font-weight:600;line-height:14px;margin:0;padding:0}`, "",{"version":3,"sources":["webpack://./src/Components/Atoms/Button/ButtonLiveTiles/ButtonLiveTiles.module.scss"],"names":[],"mappings":"AAAA,sBACE,WAAA,CACA,cAAA,CACA,WAAA,CACA,qBAAA,CACA,iBAAA,CACA,0CAAA,CAEA,sBACE,sDAAA,CACA,gDAAA,CAEA,4BACE,gDAAA,CAIJ,sBACE,sDAAA,CACA,gDAAA,CAEA,4BACE,gDAAA,CAIJ,sBACE,kDAAA,CACA,4CAAA,CAEA,4BACE,4CAAA,CAIJ,4CACE,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,cAAA,CAEA,kEACE,UAAA,CACA,WAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,qBAAA,CACA,aAAA,CAGF,kEACE,oCAAA,CACA,iBAAA,CACA,+BAAA,CACA,cAAA,CACA,iBAAA,CACA,eAAA,CACA,gBAAA,CAEA,QAAA,CACA,SAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button-container": `MDpSJRdQjtIFI4tBxLaR`,
	"button-container-blue": `gfv8EUIQqlcEmgouuueP`,
	"button-container-pink": `y6GejuR0tKIyql21wCB2`,
	"button-container-black": `iwX6wbahLA3c59l_iTQh`,
	"button-content": `vhiW5z_CwRgIEU9kpIbo`,
	"icon-wrapper": `NSkw7IwkdtoDuBm5U53s`,
	"button-text": `wo2z_j71lQPwVs2wQ5PL`
};
export default ___CSS_LOADER_EXPORT___;
